import "./assets/main.scss";

import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
//import { registerSW } from "virtual:pwa-register";
import HeroIconPlugin from "@/modules/heroIconsPlugin.js";
import { useRegisterSW } from "virtual:pwa-register/vue";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { definePreset } from "@primevue/themes";
import vue3GoogleLogin from 'vue3-google-login'

import * as Sentry from "@sentry/vue";

const app = createApp(App);
app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_KEY
})

Sentry.init({
  app,
  dsn: "https://21f26a7a6cd9a5e3ac98bea8ed4af6fd@o4508268772851712.ingest.de.sentry.io/4508268774424656",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/meetontrip\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const updateSW = useRegisterSW({
  onNeedRefresh() {
  },
  onOfflineReady() {
  },
});

const MyPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{gray.50}',
      100: '{gray.100}',
      200: '{gray.200}',
      300: '{gray.300}',
      400: '{gray.400}',
      500: '{gray.500}',
      600: '{gray.600}',
      700: '{gray.700}',
      800: '{gray.800}',
      900: '{gray.900}',
      950: '{gray.950}'
    }
  }
});


app.use(createPinia());
app.use(router);
app.use(PrimeVue, {
  // Default theme configuration
  theme: {
    preset: MyPreset,
    options: {
      prefix: 'p',
      darkModeSelector: 'nope',
      cssLayer: false
    }
  }
});
app.use(HeroIconPlugin);
app.mount("#app");
