<template>
  <div class="">
    <SplashScreen v-if="loading" />
    <k-app :class="appClass" v-else theme="ios">
      <MustVerify v-if="user?.id && !user?.is_verified" />
      <div
        class="border-b border-gray-200 px-3 py-2 flex items-center justify-between left-0 right-0 top-0 bg-transparent"
        transparent
        v-if="!isStaticPage"
      >
        <router-link to="/" class="flex items-center space-x-4" v-if="user?.id">
          <TheAvatar
            compact
            :avatar-url="user?.avatar_url"
            class="w-9 h-9 bg-primary"
          />
        </router-link>
        <div class="flex items-center space-x-3" v-if="user?.id">
          <RouterLink v-if="user?.id" to="/conversations"
            ><Icon name="ChatBubbleOvalLeftIcon" class="w-7 h-7"
          /></RouterLink>
        </div>
      </div>
      <template v-if="isStaticPage">
        <Navbar :is-static="isHome" />
        <router-view v-slot="{ Component, route }">
          <transition name="fade">
            <component :is="Component" :key="route.path" />
          </transition>
        </router-view>
        <Footerbar />
      </template>
      <div v-else ios-safe class="flex-1 overflow-y-auto">
        <router-view v-slot="{ Component, route }">
          <transition name="fade">
            <component :is="Component" :key="route.path" />
          </transition>
        </router-view>
      </div>
      <div v-if="shouldShowNavbar" :class="` toolbar `">
        <RouterLink
          class=""
          to="/home"
          :class="{ active: router.currentRoute.value.name === 'home' }"
        >
          <Icon name="HomeIcon" class="w-8 h-8" />
        </RouterLink>
        <RouterLink
          to="/users"
          :class="{ active: router.currentRoute.value.name === 'users' }"
          ><Icon name="MagnifyingGlassIcon" class="w-8 h-8"
        /></RouterLink>

        <RouterLink
          to="/notifications"
          :class="{
            active: router.currentRoute.value.name === 'notifications.index',
          }"
          ><Icon name="HeartIcon" class="w-8 h-8"
        /></RouterLink>
        <RouterLink
          :to="{
            name: 'auth.profile',
            params: { username: user.username },
          }"
          :class="{
            active: router.currentRoute.value.name === 'auth.profile',
          }"
          ><Icon name="UserIcon" class="w-8 h-8"
        /></RouterLink>
      </div>
      <div
        class="fixed bg-red-500 bg-opacity-90 text-sm text-white top-0 left-0 right-0"
        v-if="alertOpened"
      >
        <div class="p-2">
          <div v-text="alertData.title" class="font-medium"></div>
          <div v-text="alertData.message"></div>
        </div>

        <div
          class="bg-red-600 p-2 font-medium text-center text-white w-full"
          type="outline"
          @click="alertData.onConfirm"
          v-text="alertData?.okLabel"
        />
      </div>
    </k-app>
  </div>
</template>

<script setup>
import TheButton from "./components/TheButton.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import {
  kPage,
  kApp,
  kNavbar,
  kNavbarBackLink,
  kDialog,
  kToolbar,
  kLink,
  kBlock,
  kDialogButton,
  kPanel,
  kList,
  kListInput,
  kSheet,
  kChip,
  kActions,
  kActionsGroup,
  kActionsButton,
  kActionsLabel,
  kButton,
} from "konsta/vue";
import { computed, onMounted, ref, watchEffect } from "vue";
import { RouterLink, RouterView } from "vue-router";
import router from "./router";
import { eventBus } from "@/modules/eventBus";
import SplashScreen from "./components/SplashScreen.vue";
import { useUserStore } from "./stores/user";
import TheAvatar from "./components/Auth/TheAvatar.vue";
import TheLink from "./components/TheLink.vue";
import { useCategoryStore } from "./stores/categories";
import MustVerify from "./components/Auth/MustVerify.vue";
import Footerbar from "./components/Home/footerbar.vue";
import Navbar from "./components/Home/navbar.vue";

const loading = ref(true);
const user = ref(null);
const alertOpened = ref(false);
const panelIsOpen = ref(false);
const alertData = ref({
  title: "Title",
  message: "Hello world!",
  okLabel: "OK",
  cancelLabel: "Cancel",
  onConfirm: () => {
    alertOpened.value = false;
  },
});
const categoryStore = useCategoryStore();

const userStore = useUserStore();
const shouldShowNavbar = computed(() => {
  const routeName = router.currentRoute.value.name;
  return routeName !== "conversation" && routeName !== 'auth.login' && routeName !== 'auth.register' && user.value?.id;
});

onMounted(async () => {
  if (localStorage.getItem("access_token")) {
    await userStore.getUser();
    user.value = userStore.user;

    categoryStore.getCategories(); // Fetch categories on mount
  }

  loading.value = false;
});

eventBus.on("alert", (data) => {
  /*   toast(data.message, {
    autoClose: 3000,
    multiple: false,
  }); // ToastOptions */

  alertData.value = { ...alertData.value, ...data };
  alertOpened.value = true;
  setTimeout(() => {
    alertOpened.value = false;
  }, 5000);
});

const showInfo = () => {
  eventBus.emit("alert", {
    message:
      "If you need any help or need to report something on our app, you may send us an email please visit meetontrip.com/help.",
    title: "Support",
  });
};

const appClass = computed(() => {
  return [
    isStaticPage.value
      ? ""
      : "mx-auto md:max-w-3xl pt-safe pb-safe flex flex-col overflow-hidden !min-h-[calc(100dvh)] !h-[calc(100dvh)]",
  ];
});

const isHome = computed(() => {
  return router.currentRoute.value.name === "index";
});

const isStaticPage = computed(() => {
  return (
    router.currentRoute.value.name == "index" ||
    router.currentRoute.value.name == "page"
  );
});
</script>

<style lang="scss" scoped>
.toolbar {
  @apply grid grid-cols-4 w-full py-4 border-t border-stone-100 items-center;
  a {
    @apply opacity-30 flex flex-col items-center space-y-1 justify-center;
    &.active {
      @apply opacity-100;
    }
  }
}
</style>
